import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import NumberConsole from "../components/NumberConsole"
import Button from '@mui/material/Button';
import MobileNumber from "../components/MobileNumber"
import Parse from '../vendor/Parse'
import CircularProgress from '@mui/material/CircularProgress';
import ThreeCol from '../components/ThreeColLayout'
const { useEffect } = React;




const IndexPage = () => {
  const [value, setValue] = React.useState(
    dayjs('1988-09-14T12:00:01'),
  );
  const [number, setNumber] = useState('')
  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const [result, setResult] = useState([])
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const handleChange = (newValue) => {
    console.log(newValue)
    setValue(newValue);
  };


  const searchNumbers = async (query) => {
    return Parse.Cloud.run('searchPublicNumbers', {
      query: query,
      itemKey: 'Numbers'
    })
  }

  useEffect(() => {
    const accordionData = [
      {
        title: '号码分析1',
        content: <>test</>
      },
      {
        title: '',
        content: <Typography>
          <a href="https://api.whatsapp.com/send?phone=6587413159&amp;text=免费深入分析" style={{ color: "rgb(37, 211, 102)" }}>Whatsapp</a> 我们获取免费深入分析
        </Typography>
      }
    ]

    setAccordion(accordionData)
  }, [])


  return (
    <ThreeCol>
      <Seo title="Home" />

      <Box display="flex" justifyContent="center" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ textAlign: 'center' }} justifyContent="center"><h1>Find Mobile Number</h1></Box>
          <Box display="flex" justifyContent="center" flexDirection="row">
            <TextField id="outlined-basic" label="Search" variant="outlined"
              onChange={e => setNumber(e.target.value)}
            />
            <Button
              onClick={async () => {
                const _result = await searchNumbers(number)
                setResult(_result)

              }}
            >Find</Button>
          </Box>

        </LocalizationProvider>

      </Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <h2>
          {
            `${result.length} result Found`
          }
        </h2>
        {

          !!result.length && <h2><a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=Searching For ${number || ''}`}>whatsapp</a> us to see the actual number</h2>

        }

        {

          !result.length && <h2>
            <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=I want to do a more personalized search`}>whatsapp</a> us to do a more in depth search.
          </h2>

        }
      </Box>
      <br />

      <NumberConsole
        isLoading={true}
        hideDl={true}
        dataArr={result}
      />



    </ThreeCol>
  )
}

export default IndexPage
